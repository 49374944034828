.h1,
.h2,
.h3 {
  font-family: font(heading);
  font-style: normal;
  font-weight: normal;
  letter-spacing: 2px;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}

.h1 {
  font-size: 5.5rem;
  line-height: 6.5rem;
}

.h2 {
  font-size: 4.5rem;
  line-height: 5.5rem;
}

.h3 {
  font-size: 4rem;
  line-height: 5rem;
}

.copy {
  font-family: font(copy);
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  line-height: 2.2rem;
}
