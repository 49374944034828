.intro {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: color(white);
  display: flex;
  height: 100vh;
  position: relative;

  @media screen and (min-width: 768px) {
    background-position: center top;
  }

  &:after {
    background: rgba(color(black), 0.6);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

.intro__wrapper {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: auto;
  z-index: 1;
}

.intro__logo {
  fill: color(red);
  height: auto;
  margin: 2.5rem auto;
  width: 7.5rem;
}

.intro__title {
  letter-spacing: 0.5rem;
  margin-bottom: 0.5rem;
  text-shadow: 0 2px 7.5px rgba(0, 0, 0, 0.25);
}

.intro__footer {
  display: none;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    display: flex;
  }
}
