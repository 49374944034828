.footer__container {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer__text {
  font-size: 1.1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: block;

  @media screen and (min-width: 768px) {
    display: flex;
    margin-left: 0;
    margin-right: 0;
  }

}
