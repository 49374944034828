.button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: color(white);
  cursor: pointer;
  position: relative;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-family: font(heading);
  text-align: center;
  letter-spacing: 0.5rem;
  margin: 2rem 0;
  padding: 1.5rem 2rem;
  min-width: 20rem;
  border: 0.1rem solid color(white);
  background-color: transparent;
  transition: all 0.3s ease-out;

  &:hover {
    border-color: color(red);
  }
}
