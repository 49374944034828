@function font($font) {
  @return map-get($fonts, $font);
}

@function color($color) {
  @return map-get($colors, $color);
}

.flex {
  display: flex;
}

.c-white {
  color: color(white);
}

.c-red {
  color: color(red);
}

.c-black {
  color: color(black);
}

.no-underline {
  text-decoration: none;
}
