$fonts: (
  heading:  ('Building-CondensedMedium', Arial, Helvetica, sans-serif),
  copy:     ('Playfair Display', monospace),
);

$colors: (
  black:      #101010,
  green:      #56cfb5,
  red:        #cc3426,
  white:      #ffffff,
);

$containers: (
  large:  1140px,
  medium: 720px,
  small:  640px,
);
