.nav__menu__toggle {
  color: color(white);
  font-family: font(heading);
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.nav__menu {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 8rem;
  left: 0;
  right: 0;
  background-color: color(white);
  justify-content: flex-start;
  align-items: flex-end;
  padding: 2.5rem 0;

  &:before {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid color(black);
    position: absolute;
    top: 0;
    right: 3.5rem;
  }

  &.is-open {
    display: flex;
  }

  @media screen and (min-width: 768px) {
    & {
      display: none!important;
    }
  }
}

.nav__menu__item {
  width: 100%;
  padding: 2rem 0;
  text-align: right;
}

.nav__menu__item__link {
  color: color(black);
  font-family: font(heading);
  font-size: 1.6rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;

  &:active {
    color: color(red);
  }
}
