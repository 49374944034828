
.section {
    padding: 10rem 0;
    min-height: 80rem;
}

.section--gallery {
    padding-top: 20vh;
}

.section__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
}

.section__description {
    opacity: 0;
    transform: translateY(1rem);
    transition: opacity 0.8s linear 0.1s, transform 0.5s ease-in 0.3s;
    max-width: 40rem;

    .section--in-view & {
        opacity: 1;
        transform: translateY(0);
    }
}
   
.section__title {
    display: inline-block;
    position: relative;

    &:after {
        background: color(red);
        content: '';
        display: block;
        height: 0.3rem;
        transition: transform 0.3s cubic-bezier(.75,-0.5,0,1.75) 0.9s;
        transform: scaleX(0);
        transform-origin: 0 50%;
        width: 30%;

        .section--in-view & {
            transform: scaleX(1);
        }
    }
}
  
.section__image {
    max-width: 100%;
    border-radius: 0.5rem;
    opacity: 0;
    transform: scale(1.1);
    transform-origin: 50% 50%;
    transition: opacity 0.5s, transform 0.3s ease-in-out 0.2s;
  
    .section--in-view & {
        opacity: 1;
        transform: scale(1);
    }
}