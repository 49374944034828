.gallery {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  min-height: 150vh;
  max-width: map-get($containers, large);
  margin: 0 auto;
  align-content: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(280px,1fr));
  }
}

.gallery__item {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}