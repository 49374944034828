.form {
  margin: 2.5rem 0;
  padding: 0 4rem 4rem 4rem;
}

.form__field {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  
  & + & {
    margin-top: 1.5rem;
  }
}

.form__field__label {
  font-family: font(heading);
  font-size: 1.8rem;
  letter-spacing: 0.3rem;
  margin: 0.5rem 0 0.5rem 0;
  text-transform: uppercase;
  padding-right: 2rem;
}

.form__field__input,
.form__field__textarea {
  background: transparent;
  border: 1px solid rgba(color(white), 0.55);
  border-radius: 3px;
  color: color(white);
  font-family: font(copy);
  font-size: 1.6rem;
  margin: 0 0 0.75rem 0;
  max-width: 40rem;
  padding: 1.5rem;
  transition: border 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;

  &:focus,
  &:active {
    border-color: color(white);
  }

  &.has-error,
  &.has-error:focus,
  &.has-error:active {
    border-color: color(red);
  }
}

.form__field__textarea {
  min-height: 20rem;
}

.form__field__error {
  font-size: 1.2rem;
  color: color(red);
  margin-top: 0.5rem;
  letter-spacing: 0.2rem;
}
