.msg {
  margin: 1.5rem 0 0 0;
  font-size: 1.4rem;

  &.msg--sent {
    color: color(green);
  }

  &.msg--error {
    color: color(red);
  }
}
